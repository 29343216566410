.notification {
  width: $sidenav-width;
  .notification__topbar {
    height: $topbar-height;
    box-shadow: $elevation-z6;
  }

  .notification__card {
    .card__topbar {
    }

    .card__topbar__button {
      height: 24px;
      width: 24px;
      border-radius: 15px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: .9;
      .card__topbar__icon {
        // font-size: 14px !important;
      }
    }
  }
}
