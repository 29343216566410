.signup {
  background: #DEE5ED;
  

  .signup-card {
    max-width: 800px;
    border-radius: 12px !important;
    img {
      width: 200px;
    }
  }

  .wrapper {
    margin: 16;
    position: relative;
  }

  .buttonProgress {
    color: #FFA06A;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -12px;
    margin-left: -12px;
  }
}
