.scrum-board {
  .face-group {
    .avatar {
      border: 2px solid white;
      height: 28px;
      width: 28px;
      &:not(:first-child) {
        margin-left: -10px;
      }
    }
    .number-avatar {
      font-size: 14px;
      background: $warn;
    }
  }

  .list-column {
    margin: 0px 16px;
  }
  .list-column:first-child {
    margin: 0px 16px 0px 0px;
  }
  .list-column:last-child {
    margin: 0px 0px 0px 16px;
  }
}
